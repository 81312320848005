import React, { Fragment } from 'react';
import { api, openModal } from '../Modul';
import RendTables from '../component/RendTable';
import FileManager from 'devextreme-react/file-manager';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [
                { cap: "", sort: "", type: "opsi" },
                { cap: "Nama", sort: "UserName", type: "str" },
                { cap: "Perusahaan", sort: "Nama", type: "str" },
                { cap: "OS", sort: "OS", type: "str" },
                { cap: "Menu", sort: "Menu", type: "str" },
                { cap: "Lokasi", sort: "LastLocation", type: "str" }
            ],
            Posisi: "",
            Detail: {},
            idx: 0,
            mode: "user",
            Files: [],
            dir: "../../pos/assets/berkas/",
            BeforeDir: "../../pos/assets/berkas/"
        };
    }

    async componentDidMount() {
        this.main();
        if (this.state.mode == "user") {
            setInterval(() => {
                this.main();
            }, 5000);
        }
        let ColorTheme = localStorage.getItem("ColorTheme") || "dark";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async main() {
        let sql = await api("data", { act: "data log" });
        if (sql.status == "sukses") this.setState({ Data: sql.data });
    }

    async handleFiles(dir = "../../pos/assets/berkas/") {
        let BeforeDir = this.state.dir;
        let sql = await api("data", { act: "list dir", dir: dir, BeforeDir: BeforeDir });
        if (sql.status == "sukses") {
            let data = [];
            for (let dd of sql.data) {
                dd.Perusahaan = "";
                for (let aa of sql.perusahaan) if (aa.ID == dd.Nama) dd.Perusahaan = aa.Nama;
                data.push(dd);
            }
            this.setState({ Files: data, dir: dir });
        }
    }

    handleMap(data) {
        if (data.LastLocation != "") {
            this.setState({ Detail: data, Posisi: data.LastLocation });
            openModal("modalMaps");
        }
    }

    async handleDetail(data) {
        let sql = await api("data", { act: "detail system", TokenUser: data.Token });
        if (sql.status == "sukses") {
            this.setState({ Detail: sql.data });
            openModal("modalDetail");
        }
    }

    handleDetailFiles(data) {
        let Url = "https://apps.gijutsusoftware.com/";
        let File = data.path;
        if (data.extension == "png" || data.extension == "gif" || data.extension == "jpg" || data.extension == "jpeg") {
            let Detail = data;
            Detail.Url = Url + File.replaceAll("/var/www/html/pos/", "");
            this.setState({ Detail: Detail });
            openModal("modalImg");
        } else {
            window.open(Url + File.replaceAll("/var/www/html/pos/", ""));
        }
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">User Aktif</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" onClick={() => this.handleFiles()}>Files</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Update</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                <p></p>
                                <RendTables
                                    tbody={this.state.Data}
                                    thead={this.state.Field} opt={[
                                        { icon: "fas fa-street-view", fn: (e) => this.handleMap(e) },
                                        { icon: "fas fa-info-circle", fn: (e) => this.handleDetail(e) },
                                    ]}
                                    group={[{ cap: "Perusahaan", sort: "Nama" }]}
                                />
                            </div>
                            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                <p></p>
                                <div className='input-group'>
                                    <input className='form-control' value={this.state.dir} onChange={(e) => this.setState({ dir: e.target.value })} />
                                    <button className='btn btn-default' onClick={() => this.handleFiles(this.state.dir)}>
                                        <i className='fas fa-search'></i>
                                    </button>
                                </div>
                                <FileManager
                                    fileSystemProvider={this.state.Files}
                                    height={900}
                                    permissions={{
                                        create: true,
                                        copy: true,
                                        move: true,
                                        delete: true,
                                        rename: true,
                                        upload: true,
                                        download: true,
                                    }}
                                    onSelectedFileOpened={(e) => {
                                        this.handleDetailFiles(e.file.dataItem);
                                    }}
                                />
                            </div>
                            <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">...</div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalMaps" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Lokasi User</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <iframe width="100%" height="500" frameborder="0" style={{ border: "0" }} src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB_795sI-eYMaKoMy4Dvkrxtxz3qYXWb6A&q=${this.state.Posisi}`} allowfullscreen></iframe>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDetail" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail User</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className='table-responsive'>
                                    <table className='table'>
                                        <tbody>
                                            <tr>
                                                <td>Nama</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.Nama}</td>
                                            </tr>
                                            <tr>
                                                <td>Perusahaan</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.Perusahaan}</td>
                                            </tr>
                                            <tr>
                                                <td>Email</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.Email}</td>
                                            </tr>
                                            <tr>
                                                <td>Telp</td>
                                                <td>:</td>
                                                <td>{this.state.Detail.Telp}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalImg" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5">Detail Gambar</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <img src={this.state.Detail.Url} width="100%" />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalCariMenu'>Tutup</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
